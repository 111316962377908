module.exports = {
    "metadata": {
        "name": "Onport",
        "short_name": "Onport",
        "description": "Discover the latest Onport developers’ documentation, with detailed tutorials, API references and step-by-step configuration guides.",
        "language": "en",
        "url": "https://composable.onport.com",
        "pathPrefix": "/",
        "gaTrackingId": null,
        "siteImage": null,
        "favicon": "/assets/favicon.ico",
        "themeColor": "#3269c7"
    },
    "header": {
        "logo": "/assets/code.svg",
        "logoLink": "/",
        "helpUrl": "",
        "links": null,
        "enabled": true
    },
    "sidebar": {
        "enabled": true,
        "ignoreIndex": true,
        "forcedNavOrder": [],
        "expanded": [],
        "groups": [
            {
                "order": 1,
                "path": "/gettingstarted",
                "title": ":rocket: Getting Started"
            },
            {
                "order": 2,
                "path": "/inventory",
                "title": ":house: In-house inventory"
            },
            {
                "order": 3,
                "path": "/shipping",
                "title": ":package: Shipping"
            },
            {
                "order": 4,
                "path": "/customchannel",
                "title": ":computer: Custom channel"
            },
            {
                "order": 5,
                "path": "/customcarrier",
                "title": ":package: Custom Carrier"
            },
            {
                "order": 6,
                "path": "/dropshipproviders",
                "title": ":keyboard: Dropship Providers"
            },
            {
                "order": 7,
                "path": "/vendorintegration",
                "title": ":computer: Vendor Integration"
            },
            {
                "order": 8,
                "path": "/exports",
                "title": ":open_file_folder: Exports"
            },
            {
                "order": 9,
                "path": "/api-pipeline",
                "title": ":repeat: API Pipeline"
            },
            {
                "order": 10,
                "path": "/payments",
                "title": ":heavy_dollar_sign: Payments"
            },
            {
                "order": 10,
                "path": "/reseller",
                "title": ":arrows_clockwise: Reseller"
            },
            {
                "order": 10,
                "path": "/returns",
                "title": ":arrows_clockwise: Returns"
            },
            {
                "order": 10,
                "path": "/3pl",
                "title": ":arrows_clockwise: 3PL"
            },
            {
                "order": 11,
                "path": "/accounting-integration",
                "title": ":books: Accounting"
            },
            {
                "order": 12,
                "path": "/recipes",
                "title": ":hamburger: Recipes"
            }
        ],
        "links": [
            {
                "text": "Onport",
                "link": "https://onport.com"
            },
            {
                "text": "Support",
                "link": "https://jettisupport.zendesk.com/hc/en-us"
            },
            {
                "text": "API Docs",
                "link": "https://reference.jetti.io/"
            }
        ],
        "poweredBy": {}
    },
    "pwa": {
        "manifest": {
            "name": "BooGi",
            "short_name": "BooGi",
            "start_url": "/",
            "background_color": "#6b37bf",
            "theme_color": "#6b37bf",
            "display": "standalone",
            "crossOrigin": "anonymous",
            "icon": "static/assets/favicon.ico",
            "description": "Discover the latest Onport developers’ documentation, with detailed tutorials, API references and step-by-step configuration guides.",
            "cache_busting_mode": "none",
            "include_favicon": false,
            "lang": "en"
        },
        "enabled": true
    },
    "social": {},
    "features": {
        "editOnRepo": {
            "editable": true,
            "location": "https://github.com/taact-dev/jetti.dev-docs",
            "type": "github"
        },
        "search": {
            "enabled": false,
            "indexName": "dev-docs",
            "algoliaAppId": "COG56C35NE",
            "algoliaSearchKey": "97074e811b536f0e79e9fc0b0524076f",
            "algoliaAdminKey": "64acc22bf9dccf3c3c8f5140f4f2c23d",
            "excerptSize": 8000,
            "engine": "algolia",
            "placeholder": "Search",
            "startComponent": "icon",
            "debounceTime": 380,
            "snippetLength": 23,
            "hitsPerPage": 10,
            "showStats": true,
            "localSearchEngine": {
                "encode": "advanced",
                "tokenize": "full",
                "threshold": 2,
                "resolution": 30,
                "depth": 20
            },
            "pagination": {
                "enabled": true,
                "totalPages": 10,
                "showNext": true,
                "showPrevious": true
            }
        },
        "toc": {
            "show": true,
            "depth": 4
        },
        "previousNext": {
            "enabled": true,
            "arrowKeyNavigation": true
        },
        "scrollTop": false,
        "showMetadata": true,
        "propagateNetlifyEnv": true,
        "pageProgress": {
            "enabled": true,
            "excludePaths": [
                "/"
            ],
            "height": 3,
            "prependToBody": false,
            "color": "#A05EB5",
            "includePaths": [
                "/configuration/settingup/features"
            ]
        },
        "mermaid": {
            "language": "mermaid",
            "theme": "dark",
            "options": {},
            "width": 400,
            "height": 300
        },
        "rss": {
            "enabled": false,
            "showIcon": true,
            "title": "My RSS feed",
            "copyright": "",
            "webMaster": "M",
            "managingEditor": "",
            "categories": [
                "GatsbyJS",
                "Docs"
            ],
            "ttl": "60",
            "matchRegex": "^/",
            "outputPath": "/rss.xml",
            "generator": "gidocs"
        },
        "darkMode": {
            "enabled": true,
            "default": false
        },
        "publishDraft": false,
        "fullScreenMode": {
            "enabled": false,
            "hideHeader": false,
            "hideToc": false,
            "hideSidebar": false
        }
    }
};